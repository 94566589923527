/* Additional Styles that couldnt be applied with Styled Components*/

@import "@blueprintjs/popover2/lib/css/blueprint-popover2.css";

body {
  background: white;
  margin: 0 !important;
  padding: 0 !important;
}

.flex-1 {
  flex: 1;
}

.flex-0_9 {
  flex: 0.9;
}

.flex-0_8 {
  flex: 0.8;
}

.flex-0_7 {
  flex: 0.7;
}

.flex-0_6 {
  flex: 0.6;
}

.flex-0_5 {
  flex: 0.5;
}

.flex-0_4 {
  flex: 0.4;
}

.flex-0_3 {
  flex: 0.3;
}

.flex-0_2 {
  flex: 0.2;
}

.flex-0_1 {
  flex: 0.1;
}

.label_flex-0_1 .bp3-label {
  flex: 0.1;
}

.label_flex-0_2 .bp3-label {
  flex: 0.2;
}

.label_flex-0_3 .bp3-label {
  flex: 0.3;
}

.label_flex-0_4 .bp3-label {
  flex: 0.4;
}

.label_flex-0_5 .bp3-label {
  flex: 0.5;
}

.label_flex-0_6 .bp3-label {
  flex: 0.6;
}

.label_flex-0_7 .bp3-label {
  flex: 0.4;
}

.label_flex-0_8 .bp3-label {
  flex: 0.8;
}

.label_flex-0_9 .bp3-label {
  flex: 0.9;
}

.label_flex-1 .bp3-label {
  flex: 1;
}

.bp3-input-group.bp3-large > .bp3-icon {
  margin: 0;
  padding: 12;
  color: white;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  background-color: #2e9e9c;
}
.bp3-input-group.bp3-large .bp3-input:not(:first-child) {
  padding-left: 50px;
}

.sidenav---icon-bar---u1f02 {
  background-color: #2e9e9c !important;
}

.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u.sidenav---expanded---1KdUL
  > .sidenav---navitem---9uL5T::after,
.sidenav---sidenav-nav---3tvij
  > .sidenav---sidenav-navitem---uwIJ-:hover.sidenav---highlighted---oUx9u.sidenav---expanded---1KdUL
  > .sidenav---navitem---9uL5T::after {
  background-color: white;
}

.sidenav---sidenav-subnav---1EN61
  > .sidenav---sidenav-subnavitem---1cD47:nth-child(2) {
  margin-top: 2px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.bp3-form-group {
  margin: 0;
}

.bp3-form-group.bp3-inline {
  height: auto;
}

.bp3-menu-item.bp3-intent-primary.bp3-active {
  background-color: #2e9e9c;
}

.bp3-spinner.bp3-intent-primary .bp3-spinner-head {
  stroke: #2e9e9c;
}

.center-self-vertical {
  top: 50%;
  position: relative;
  transform: translateY(-50%);
}

.center-self-horizontal {
  left: 50%;
  position: relative;
  transform: translateX(-50%);
}

.center-self {
  top: 50%;
  left: 50%;
  position: relative;
  transform: translateY(-50%, -50%);
}

.bp3-button .bp3-icon > svg {
  width: 20;
  height: 20;
}

.ReactTable .rt-table {
  background-color: #f1f2f2;
}

.bp3-toast.bp3-intent-primary {
  background-color: #2e9e9c;
}

.create-form .bp3-input-group .bp3-input {
  background-color: #f1f2f2;
}

.create-form textarea.bp3-input,
textarea.bp3-input {
  background-color: #f1f2f2;
	height: 100;
	max-height: 150px;
}

.create-form .bp3-tag-input {
  background-color: #f1f2f2;
}

.create-form .bp3-file-upload-input {
  background-color: #f1f2f2;
}

.bp3-file-upload-input::after {
  color: #ffffff;
  background-color: #2e9e9c;
  background-image: none;
}

.bp3-file-upload-input {
  pointer-events: none;
}

.bp3-intent-danger .bp3-file-upload-input {
  -webkit-box-shadow: 0 0 0 0 rgba(219, 55, 55, 0), 0 0 0 0 rgba(219, 55, 55, 0),
    inset 0 0 0 1px #db3737, inset 0 0 0 1px rgba(16, 22, 26, 0.15),
    inset 0 1px 1px rgba(16, 22, 26, 0.2);
  box-shadow: 0 0 0 0 rgba(219, 55, 55, 0), 0 0 0 0 rgba(219, 55, 55, 0),
    inset 0 0 0 1px #db3737, inset 0 0 0 1px rgba(16, 22, 26, 0.15),
    inset 0 1px 1px rgba(16, 22, 26, 0.2);
}

.bp3-form-group,
.bp3-file-input {
  max-width: 100%;
}

.bp3-button.bp3-minimal:disabled {
  opacity: 0.3 !important;
  color: #585858 !important;
}

.bp3-control .bp3-control-indicator {
  font-size: 25;
}

.bp3-control.bp3-switch input:checked ~ .bp3-control-indicator {
  background-color: #2e9e9c;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.btn-download .bp3-icon > svg:not([fill]) {
  fill: #2e9e9c;
}

.bp3-toast-container.bp3-toast-container-bottom {
  z-index: 1001;
}

.bp3-menu {
	min-width: 200px;
}

.bp3-button-text {
	width: 100%;
	text-align: center;
}

.bp3-button.bp3-intent-primary:disabled, .bp3-button.bp3-intent-primary.bp3-disabled {
	box-shadow: inset 0 0 0 1px rgba(16, 22, 26, 0.4), inset 0 -1px 0 rgba(16, 22, 26, 0.2);
}

.bp3-table-container {
	box-shadow: 0 0 1px white!important;
}

.bp3-table-quadrant, .bp3-table-column-headers{
	background: white!important;
	background-color: white!important;
}

.bp3-table-cell {
	font-size: 16px!important;
	color: #585858;
	padding-top: 5px!important;
	background-color: white;
	box-shadow: 0 0 1px white!important;
}

.bp3-table-editable-text {
	color: #137cbd
}

.bp3-table-column-name {
	font-size: 18px!important;
	background-color: #2e9e9c;
	color: white;
	text-align: center!important;
}

.bp3-table-truncated-text
	> .bp3-checkbox {
		padding-top: 7px!important;
	}

.bp3-control.bp3-large:not(.bp3-align-right) {
	padding-left: 34px;
}

.bp3-control input:checked ~ .bp3-control-indicator {
	background-color: #2e9e9c;
}

.bp3-toast-container {
  position: fixed !important;
}
